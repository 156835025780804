<!-- @format -->
<script lang="ts">
  import { getStoresContext } from "~/stores";
  import { getMovieDuration } from "./utils";
  import type { Biorex } from "~/mall-of-tripla/biorex/type";

  export let request_store_key;
  export let biorex: Biorex;
  export let aspectRatio = 0

  const { T } = getStoresContext(request_store_key);
  $: t = $T;
  $: duration = getMovieDuration(biorex.start, biorex.end);
</script>

<div class="biorex-card">
  <div class="biorex-card-image-wrapper" style="padding-top: {aspectRatio * 100}%;">
    {#if biorex.image}
      <img
        loading="lazy"
        alt={biorex.title}
        data-src={biorex.image}
        class="lazy-loading-src" />
    {/if}
  </div>
  <div class="biorex-card-content-wrapper">
    <div class="title" title={biorex.title}>{@html biorex.title}</div>
    <div>{duration}</div>
    <div class="genres" title={biorex.genres}>{biorex.genres}</div>
  </div>
  <a
    href={biorex.url}
    target="_blank"
    rel="noreferrer"
    class="purple-outline-button">{t`buy-movie-ticket`}</a>
</div>

<style lang="scss">
  .title,
  .genres {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .biorex-card {
    display: flex;
    position: relative;
    width: 100%;
    color: inherit;
    text-decoration: none;
    height: 100%;
    justify-content: space-between;
    flex-direction: column;
    padding: 0 8px;
    @media screen and (min-width: 768px) {
      height: 470px;
    }
  }
  .biorex-card-image-wrapper {
    position: relative;
    background-color: #fff;
  }

  .biorex-card-image-wrapper img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .biorex-card-content-wrapper {
    padding: 8px 0;
    font-size: 16px;
    text-align: center;
    @media screen and (min-width: 768px) {
      padding: 0;
    }
  }
  a {
    display: block;
  }
</style>
