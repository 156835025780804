import type { Biorex } from "~/mall-of-tripla/biorex/type";

export function readBrokenShows(xml: Document): Biorex[] {
    const show_start = xml.querySelectorAll('Schedule ID');
    const ids = [...show_start].map(id_el => {
        const id = id_el.textContent;
        id_el.setAttribute('id', `show-${id}`);
        return id;
    });

    const shows = ids.map(id => (
        {
            id: id,
            title: xml.querySelector(`#show-${id} ~ Title`)?.textContent,
            genres: xml.querySelector(`#show-${id} ~ Genres`)?.textContent,
            start: xml.querySelector(`#show-${id} ~ dttmShowStart`)?.textContent,
            end: xml.querySelector(`#show-${id} ~ dttmShowEnd`)?.textContent,
            url: xml.querySelector(`#show-${id} ~ ShowURL`)?.textContent,
            event_id: xml.querySelector(`#show-${id} ~ EventID`)?.textContent,
            image: xml.querySelector(`#show-${id} ~ Images EventMediumImagePortrait`)?.textContent,
        }
    ));
    return removeDuplicateBiorexShow(shows)
}


export function getMovieDuration(start: string | number | Date, stop: string | number | Date) {
    if (!start || !stop) throw new Error(`Bad date`);
    let diffMs = new Date(stop) - new Date(start);
    let diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
    let diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000);
    return diffHrs + ' h ' + diffMins + ' min';
}

function removeDuplicateBiorexShow(biorex: Biorex[]) {
    const EventIDs = biorex.map((o) => o.event_id);
    return biorex.filter(({ event_id }, index) => !EventIDs.includes(event_id, index + 1))
}